
import { Component, Vue } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import echarts from 'echarts'
import { HydrantMapDetail } from '@/types/hydrant.d'
import { drawPolygon } from '@/utils/formatData'

@Component({
  name: 'DetailMap'
})
export default class DetailMap extends Vue {
  private drawer = false
  private map: AMap.Map | undefined = undefined
  private ploygons: Array<AMap.Polygon> = []
  private markers: Array<AMap.Marker> = []

  private userImg = require('@/assets/icon/hydrant/icon_map_xiaofangshuan_yongshuizhong.svg')
  private userImgCursor = require('@/assets/icon/hydrant/icon_map_xiaofangshuan_yongshuizhong_dianji.svg')
  private unUserImg = require('@/assets/icon/hydrant/icon_map_xiaofangshuan_zaixian.svg')
  private unUserImgCursor = require('@/assets/icon/hydrant/icon_map_xiaofangshuan_zaixian_dianji.svg')
  private warningImg = require('@/assets/icon/hydrant/icon_map_xiaofangshuan_gaojing.svg')
  private warningImgCursor = require('@/assets/icon/hydrant/icon_map_xiaofangshuan_gaojing_dianji.svg')

  private hydrantList: Array<HydrantMapDetail> = []
  private drawerData: HydrantMapDetail | null = null

  get projectId () {
    return this.$route.params.projectId as string
  }

  $refs!: {
    waterChart: any;
    warnChart: any;
  }

  created () {
    this.getProjectLocation()
  }

  destroyed (): void {
    if (this.markers) {
      this.markers.forEach((item: AMap.Marker) => {
        item.off('click', this.drawerOpen)
      })
    }
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', this.drawerClose)
      })
    }
    if (this.map) {
      this.map.off('click', this.drawerClose)
      this.map.destroy()
    }
  }

  // 获取项目信息及项目电子围栏
  getProjectLocation () {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectId: this.projectId
    }).then((res) => {
      this.$nextTick(() => {
        this.loadMap(res.projectInfo, res.projectLocation)
      })
    })
  }

  // 获取设备信息
  getHydrantList () {
    this.$axios.get<{ fireFightingList: Array<HydrantMapDetail>; userWaterAmount: string; unRunStateAmount: string; runStateAmount: string; unUserWaterAmount: string }>(this.$apis.hydrant.selectFireFightingInfoMap, {
      projectId: this.projectId
    }).then((res) => {
      this.hydrantList = res.fireFightingList || []
      const waterData = [{
        name: '用水中',
        value: res.userWaterAmount
      }, {
        name: '未用水',
        value: res.unUserWaterAmount
      }]
      const warnData = [{
        name: '正常',
        value: res.unRunStateAmount
      }, {
        name: '告警',
        value: res.runStateAmount
      }]
      this.drawEchart('用水统计', 'waterChart', ['#57DA87', '#499DFF'], waterData)
      this.drawEchart('告警统计', 'warnChart', ['#1B85FF', '#FF341B'], warnData)
      this.drawMarker(this.hydrantList)
    })
  }

  // 在线离线图
  drawEchart (title: string, refName: 'waterChart' | 'warnChart', colors: Array<string>, data: Array<{ name: string; value: string }>) {
    const pieEchart: any = echarts.init(this.$refs[refName])
    const option = {
      title: {
        text: title,
        textStyle: {
          align: 'center',
          color: '#000',
          fontSize: 14,
          fontWeight: 'bold'
        },
        top: 20,
        left: 24
      },
      color: colors,
      legend: {
        icon: 'circle',
        itemHeight: 6,
        show: true,
        itemGap: 15,
        right: 20,
        top: '45%',
        orient: 'vertical',
        textStyle: {
          fontSize: 14 // 字体大小
        },
        formatter: (item: string) => {
          return item === data[0].name ? `${item}  ${data[0].value}` : `${item}  ${data[1].value}`
        }
      },
      series: [{
        name: '',
        type: 'pie',
        clockWise: true,
        radius: ['38%', '65%'],
        center: ['35%', '58%'],
        itemStyle: {
          normal: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            }
          }
        },
        hoverAnimation: false,
        data: data
      }]
    }
    pieEchart.setOption(option)
  }

  // 渲染地图
  loadMap (projectInfo: {longitude: number; latitude: number}, list: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      const map = new AMap.Map('map', {
        center: center,
        zoom: 11
      })
      this.map = map
      this.ploygons = drawPolygon(this.map, list || [], null, this.drawerClose)

      // 获取设备信息
      this.getHydrantList()

      // 点击地图关闭抽屉
      this.map.on('click', this.drawerClose)
    })
  }

  // 获取图片
  getIcon (runState: string, waterStatus: string, isCurrent: boolean) {
    if (!isCurrent) {
      return (runState === '0' ? (waterStatus === '1' ? this.userImg : this.unUserImg) : this.warningImg)
    } else {
      return (runState === '0' ? (waterStatus === '1' ? this.userImgCursor : this.unUserImgCursor) : this.warningImgCursor)
    }
  }

  // 地图打点
  drawMarker (list: Array<HydrantMapDetail>) {
    this.markers = []
    if (list && list.length > 0) {
      // 循坏将设备marker添加到地图
      list.forEach((item: HydrantMapDetail, index: number) => {
        if (item.longitude && item.latitude) {
          const icon = this.getIcon(item.runState, item.waterStatus, false)
          const marker: any = new AMap.Marker({
            icon: new AMap.Icon({
              // 图标的取图地址
              image: icon
            }),
            offset: new AMap.Pixel(-18, -48),
            position: new AMap.LngLat(Number(item.longitude), Number(item.latitude))
          })
          // 用水中（waterStatus === '1'）时，图标上方添加动态效果
          if (item.runState === '0' && item.waterStatus === '1') {
            marker.setLabel({
              direction: 'top',
              content: '<div class="marker-label-content"></div>'
            })
          }
          marker.detail = item
          marker.on('click', this.drawerOpen)
          this.map && this.map.add(marker)
          this.markers.push(marker)
        }
      })
    }
  }

  // 抽屉打开
  drawerOpen (e: any) {
    if (this.drawerData === null || (this.drawerData && this.drawerData.fightingId !== e.target.detail.fightingId)) {
      if (this.drawerData && this.drawerData.fightingId !== e.target.detail.fightingId) {
        this.drawerClose()
      }
      this.drawerData = e.target.detail as HydrantMapDetail
      // 点击切换大图标
      const iconClick = this.getIcon(e.target.detail.runState, e.target.detail.waterStatus, true)
      e.target.setOffset(new AMap.Pixel(-22, -64))
      e.target.setIcon(iconClick)
    }
    this.drawer = true
  }

  // 抽屉关闭
  drawerClose () {
    if (this.drawer) {
      this.drawer = false
      // 将图标替换
      if (this.map && this.drawerData) {
        const fightingId = this.drawerData.fightingId || ''
        const index = this.map.getAllOverlays('marker').findIndex((item: any) => item.detail.fightingId === fightingId)
        const icon = this.getIcon(this.drawerData.runState, this.drawerData.waterStatus, false)
        const marker = this.map.getAllOverlays('marker')[index] as AMap.Marker
        marker.setOffset(new AMap.Pixel(-18, -48))
        marker.setIcon(icon)
      }

      // 清空抽屉
      this.drawerData = null
    }
  }
}
